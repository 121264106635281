import React from 'react';
import StandardLayout from '../../layouts/standard';
import pdf4 from '../../assets/handpicked-series.pdf';

export default function HandpickedSeries(props) {
    return <StandardLayout title="Handpicked Series">
        <div className="uk-section uk-container uk-margin">
        <embed src={pdf4} width="100%" style={{height: "80vh"}} type="application/pdf"/>
        </div>
    </StandardLayout>
}